import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
//import App from './App';
import reportWebVitals from './reportWebVitals';

import Login from './pages/login';
import Signup from './pages/signup';
import Profile from './pages/profile';
import Account from './pages/account';
import Reset from './pages/reset';
import Deposit from './pages/deposit';
import Track from './pages/track';
import Verify from './pages/verifyy';
//Verify
import Dashboard from './pages/dashboard';
import Forgot from './pages/forgot';
import Reset0 from './pages/forgot/reset';
//Forgot
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const contentDelayer = async ({ params }) => {

  return new Promise((a,b)=>{
    setTimeout(()=>{
      a({});
    },2000);
  })
  /*const res = await fetch(`${api}/${params.id}`);
  const resJson = await res.json();

  return resJson;*/
};
//Forgot
const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
    loader:contentDelayer
  },{
    path: "/login",
    element: <Login />,
    loader:contentDelayer
  },{
    path: "/signup",
    element: <Signup />,
    loader:contentDelayer
  },{
    path: "/verifyy",
    element: <Verify />,
    loader:contentDelayer
  },{
    path: "/forgot",
    element: <Forgot />,
    loader:contentDelayer
  },{
    path: "/complete/reset",
    element: <Reset0 />,
    loader:contentDelayer
  },{
    path: "/account",
    element: <Account />,
    loader:contentDelayer
  },{
    path: "/dashboard",
    element: <Dashboard />,
    //loader:contentDelayer
  },{
    path: "/track",
    element: <Track />,
    loader:contentDelayer
  },{
    path: "/profile",
    element: <Profile />,
    loader:contentDelayer
  },{
    path: "/reset",
    element: <Reset />,
    loader:contentDelayer
  },{
    path: "/deposit",
    element: <Deposit />,
    loader:contentDelayer
  },
],{
  basename: '/wp-user'
});
/*
,{
  basename: '/wp-user'
}
*/

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterProvider router={router} />
);

/*
  
    {/*<React.StrictMode><App />
  </React.StrictMode>*/
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import si from './si.module.scss';


import Pair from '../../common/Pair';
import LoInput from '../../common/LoInput';
import LoButton,{LoLink} from '../../common/LoButton';

import { useLoaderData, useNavigation, Link } from "react-router-dom";
import SiteLoader from '../../common/SiteLoader';

import {counli} from './li';
import Notification from '../../common/Notification';
// Notification
import {LoSelect2} from '../../common/LoSelect';
import {SignupProvider,useSignupCtx} from './context';

export  function InsideSignup(){

	const {disabled,notice,errors,terms,setTerms,
                changeSomething,loading,confirmPass,signup}=useSignupCtx();

	return (<div className={`${si.con} content`}>

		<Notification newnotice={notice} cb={()=>{
			//alert("good");
		}} />

		<div className={si.img}>
		<img src="photos/2.svg" />
</div>

<Pair title="Sign up now!"
subtitle="Let's set up your account in just a couple of steps." />

<LoInput icon="user" label="Name *" placeholder="Full Name" onChange={(e)=>{
	changeSomething('fullname',e.target.value);
}} />
{/*errors?.fullname?<p>{errors?.fullname}</p>:null*/}
<LoInput icon="username"  onChange={(e)=>{
	changeSomething('username',e.target.value);
}}  label="Username *" placeholder="Username" />
{/*errors?.username?<p>{errors?.username}</p>:null*/}

<LoInput icon="email" onChange={(e)=>{
	changeSomething('email',e.target.value);
}}  label="Email *" placeholder="Email Address" />
{/*errors?.email?<p>{errors?.email}</p>:null*/}

<LoInput type="tel" icon="phone" onChange={(e)=>{
	changeSomething('phone',e.target.value);
}}   label="Phone *" placeholder="Phone Number" /> 
{/*errors?.phone?<p>{errors?.phone}</p>:null*/}

<LoSelect2 icon="country" onChange={(e)=>{
	changeSomething('country',e.target.value);
}}  label="Select Country *" placeholder="Select Country" >
	{counli?.map((w,i)=>{
                return (<option key={i}>{w.name}</option>);
        })}
                                                 </LoSelect2>

{/*errors?.country?<p>{errors?.country}</p>:null*/}
{/*
<LoInput icon="country" onChange={(e)=>{
	changeSomething('country',e.target.value);
}}  label="Select Country *" placeholder="Select Country" />
*/}
		
<LoInput  onChange={(e)=>{
	changeSomething('password',e.target.value);
}}   icon="key" type="password" label="Password *" placeholder="Password" />
{/*errors?.password?<p>{errors?.password}</p>:null*/}
<LoInput  onChange={(e)=>{
	confirmPass('conf_pwd',e.target.value);
}}   icon="key" type="password" label="Confirm Password *
" placeholder="Password" />
{/*errors?.conf_pwd?<p>{errors?.conf_pwd}</p>:null*/}

<div className={si.terms}>
<input type="checkbox" onChange={(e)=>{
        //console.log(e.target.value); 
        setTerms(!terms);
        //changeSomething('terms',e.target.value);
}}  /><div>&nbsp;
You agree <a href="terms.pdf" style={{color:'#fff'}}>terms and conditions</a></div>
</div><br/>

{/*disabled={disabled}*/}
<LoButton color="red" loading={loading}  onClick={()=>{
	signup();
}} text="Create Account" />
		
<div className={si.not}>Already have an account?   <a href="login">Login</a></div>
		
		</div>);
}

export default function Signup(){
        const result = useLoaderData();
        const navigation = useNavigation(); 
        if (navigation.state === "loading") {
            return (<SiteLoader />);
        }
	return (<SignupProvider>
		<InsideSignup />
	</SignupProvider>);
}
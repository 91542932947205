import s from './reset.module.scss';
import i from '../../common/icons/i.module.scss';
import LoButton,{LoLink} from '../../common/LoButton';
//ShadowBox
import Pair2 from '../../common/Pair2';
import ShadowBox from '../../common/ShadowBox';
import Footer from '../../common/Footer';
//Footer

import { useLoaderData, useNavigation, Link } from "react-router-dom";
import SiteLoader from '../../common/SiteLoader';

import Notification from '../../common/Notification';
import {userurl} from '../../common/shared';
import {ResetProvider,useResetCtx} from './context';
import {LoInput2} from '../../common/LoInput';
export function InsideReset(){

	const {updatepwd,done,changeSomething,confirmPass,loading,
		disabled,notice}=useResetCtx();
	return (<>{done?<div className={`${s.con} content`}>


<Notification newnotice={notice} cb={()=>{ 
			window.location.reload();
		}} />

<div className={s.nav}> 
<div className={i.right}  onClick={()=>{
	window.location.href=`${userurl}/account`;
}}></div>
<div > Reset Password </div>
<div  ></div>

</div>

<div className={s.ibox2}>
<div className={i.shield}> 
</div>
</div>


<ShadowBox text="Protect & Secure Your Account  " />

<LoInput2 type="password" onChange={(e)=>{
	changeSomething('c_pass',e.target.value);
}}  label="Old Password *" placeholder="Old Password" />
<LoInput2 type="password" onChange={(e)=>{
	changeSomething('n_pass',e.target.value);
}} label="New Password *" placeholder="New Password" />
<LoInput2 type="password" onChange={(e)=>{
	confirmPass('conf_pass',e.target.value);
}} label="Confirm New Password *" placeholder="Confirm New Password" />

<p>&nbsp;</p>
		<LoButton disabled={disabled}
		 loading={loading} color="red" text="Update Password"
	 onClick={()=>{
	 	updatepwd();
	 }} />


<Footer />
		</div>:null}</>
		);
}

export default function Reset(){
	const result = useLoaderData();
	const navigation = useNavigation(); 
	if (navigation.state === "loading") {
	    return (<SiteLoader />);
	}
	return (<ResetProvider><InsideReset /></ResetProvider>);

	}
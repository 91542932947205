
import no from './no.module.scss';
import i from './i.module.scss';
import {useEffect,useState} from 'react';

export default function ShortNotification({newnotice=undefined,cb=undefined}){

	const [m,setM]=useState(undefined);
	const [icon,setIcon]=useState("success");
	useEffect(()=>{
		setM(newnotice);
		if (newnotice?.type=="success") {
			setIcon("success");
			setTimeout(()=>{
				setM(undefined);
				if (cb) {cb();}else{
				window.location.reload();}
			},3000);
		}else if (newnotice?.type=="error") {
			setIcon("error");
		}else{
			if (newnotice?.icon) {
				setIcon(newnotice?.icon);
			}else{
			setIcon("error");}
		}
	},[newnotice]);
	return (<>{m?<div className={no.no}>
		<div className={no.child}>
			<div className={i[icon]}></div> 
			{m?.message?<div>{m?.message}</div>:null} 
		</div>
		</div>:null}</>);
}
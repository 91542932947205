import React, { useState, useEffect } from "react";

//import { classlist, api, slugify } from '../data';

import {url} from "../../../common/shared";
import { useNavigate } from "react-router-dom";
//import { apiBase, adminBase, nonAuth, loginpath } from "./config";
//import { nonAuth } from "../routr";

//import Swal from "sweetalert2";
//import withReactContent from "sweetalert2-react-content";
//const MySwal = withReactContent(Swal);

const ProfileCtx = React.createContext();
/*https://app.cryptotradeoptions.net/dashboard*/

export const useProfileCtx = () => React.useContext(ProfileCtx);

export const ProfileProvider = ({ children }) => {
    //house crud methods

    const [da,setDa]=useState({});
    const [disabled,setDisabled]=useState(true);
    const [loading,setLoading]=useState(false);

    const [errors,setE]=useState({
        fullname:"should not be empty" ,
        email:"should not be empty",
        phone:"should not be empty"});

    const validateConditions=(k,v,rules)=>{
    	//Object.keys(rules).forEach((rule)=>{

    		//console.log(rule);
    		let rule=Object.keys(rules)[0];
    		let rulev=rules[rule];



	    	if (rule=="min") {
	    		console.log(`${v?.length}<${rulev}`)
	    		if (v?.length<rulev) {
	    			console.log("less");
	    			setE({...errors,[k]:`${k} must be more than ${rules.min} characters.`});
	    		}else{
	    			let y={...errors};
	    			delete y[k];
	    			setE({...y});
	    		}
	    	}else if (rule=="max") {
	    		if (v?.length>rules[rule]) {
	    			setE({...errors,[k]:`${k} must not be more than ${rules.max} characters.`});
	    		}else{
	    			let y={...errors};
	    			delete y[k];
	    			setE({...y});
	    		}
	    	}else if (rule=="validEmail") {
	    		var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  				if (v?.match(validRegex)) {
  					let y={...errors};
	    			delete y[k];
	    			setE({...y});
  				}else{
  					setE({...errors,[k]:"Invalid Email"});
  				}
	    	} 
 
    //})
    }

    const changeSomething=(k,v)=>{ 
    	setDa({...da,[k]:v});
 
    	if (k=="fullname") {
    		//validateConditions(k,v,{min:5,max:10});
    		validateConditions(k,v,{min:5});
    		//validateConditions(k,v,{max:10});
    	}
        if (k=="email") {
            validateConditions(k,v,{validEmail:true});
        } 
    	if (k=="phone") {
    		validateConditions(k,v,{min:7});
    		//validateConditions(k,v,{max:20});
    		//validateConditions(k,v,{min:5,max:20});
    	}  
    }
    //loading
    useEffect(()=>{
    	console.log(errors);
    	if (Object.keys(errors).length==0) {
    		setDisabled(false);
    	}else{
    		setDisabled(true);
    	}
    },[errors]); 

    const [done,setD]=useState(false);

    const [oldData,sOldData]=useState({});

    const [notice,setNotice]=useState(undefined);

    const updatepro=()=>{
    	//let url="";
        const formData = new FormData();
        Object.keys(da).forEach((e)=>{
            formData.append(e, da[e]);
        });
        formData.append("token",localStorage.getItem("token",null));
        formData.append('updatedetails', 'updatedetails');
    	fetch(`${url}/api/user.php`, {
          method: "POST",
          body: formData
        }).then((res) => res.json()).then((c)=>{

            setNotice(c);
            if (c?.status) {
                 //done
            }else{
                //message
            } 
        }).catch((e)=>{
            console.log(e);
        })
    }
    const getpro=()=>{
        //let url="";


        let r=new FormData();
        r.append("getdetails","getdetails");
        r.append("token",localStorage.getItem("token",null)); 

        fetch(`${url}/api/user.php`, {
          method: "POST",
          body: r
        }).then((res) => res.json()).then((c)=>{
            setD(true);
            if (c?.status) {
                setDa({...c?.user});
                sOldData(c?.user);
                setE({});
            }
        }).catch((e)=>{
            console.log(e);
        });
    }

    useEffect(()=>{
        let y=localStorage.getItem("token");
        if (y==null) {
            window.location.href="";
        }else{
            
            getpro();
        }
    },[]);


    return (
        <ProfileCtx.Provider
            value={{notice,
                done,oldData,
                disabled,loading,
                changeSomething,
                errors,updatepro,getpro
            }}
        >
            {children}
        </ProfileCtx.Provider>
    );
};

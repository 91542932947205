import {useEffect,useState} from 'react';
import s from './li.module.scss';
export  function LetterIcon({user,onClick}){
	const [initial,setI]=useState("");
	useEffect(()=>{
		if (user) {
			if (user?.fullname) {
				let text = user?.fullname;
				let result = text.trim();
				setI(result.substring(0, 1));
			}
		}
	},[user]);
	return (<div className={s.b} onClick={onClick}>
		<div>{initial}</div>
		</div>);
}


export  function LetterIconSmall({user,onClick}){
	const [initial,setI]=useState("");
	useEffect(()=>{
		console.log(user);
		if (user) {
			if (user?.fullname) {
				let text = user?.fullname;
				let result = text.trim();
				setI(result.substring(0, 1));
			}
		}
	},[user]);
	return (<div className={s.b2} onClick={onClick}>
		<div>{initial}</div>
		</div>);
}
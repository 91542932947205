
import eb from './eb.module.scss';
import {LoInput2} from '../../../common/LoInput';
import i from './i.module.scss';

import {TrackProvider,useTrackCtx} from '../context';

export default function EnterBox(){

	const {getD,sOrderId}=useTrackCtx();

	return (<>

		<div className={eb.ts}>Track Shipments </div> 

		<div className={eb.eb}>
		<div className={eb.wr}><LoInput2 style={{textAlign:'center'}} onChange={(e)=>{
			sOrderId(e.target.value)
		}} eClassName="nmb" placeholder="Enter your tracking code" />
		

</div>

<div className={i.but} onClick={()=>{
	getD();
}}>{/*
<div className={i.boxi}>		</div>*/}<div>Search Now!</div>
</div>
 

		</div></>);
}

import LoInput from '../../common/LoInput';
import LoButton,{LoLink} from '../../common/LoButton';
import Pair from '../../common/Pair';

import { useLoaderData, useNavigation, Link } from "react-router-dom";
import SiteLoader from '../../common/SiteLoader';

import {url,admin} from "../../common/shared";
import {SigninProvider,useSigninCtx} from './context';
//Pair
import st from './lo.module.scss';

import Notification from '../../common/Notification';

export  function InsideLogin(){

const {disabled,loginres,siteName,
                changeSomething,loading,notice,signin}=useSigninCtx();

	return (<div className={`${st.con} content`}>

		<Notification newnotice={notice} cb={()=>{ 

			if (!loginres?.error) {
			if (loginres?.jwt) { 
	            window.location.href="dashboard";
	        }else{
	            window.location.href=admin;
	        }}

		}} />

		<div className={st.img}>
		<img src="photos/1.svg" />
</div>

<Pair title={`Welcome to ${siteName}`}
subtitle="Log in to your account and start your adventure." />

		<LoInput icon="user" onChange={(e)=>{
	changeSomething('username',e.target.value);
}} label="User *" placeholder="Username" />
		<LoInput icon="key" onChange={(e)=>{
	changeSomething('password',e.target.value);
}} type="password" label="Password *" placeholder="Password" />

<div className={st.links}>
<div className={st.linksA}> <input type="checkbox" /><div> &nbsp; Remember me</div></div>

<div className={st.linksB}><div className={st.end}><a href="forgot">Forgot your password ?</a></div></div>
</div> 

		<LoButton color="red" text="Login Account" loading={loading} disabled={disabled} onClick={()=>{
	signin();
}}/>
		<div className={st.not}>Don&apos;t have an account ? <a href="signup">Sign up</a></div>
		<hr/>
		<div className={st.not}><a href="signup">Track your shipment </a></div>
		
		<LoLink color="black" href="track" text="Tracking" />

		</div>);
}


export default function Login(){
	const result = useLoaderData();
	const navigation = useNavigation(); 
	if (navigation.state === "loading") {
	    return (<SiteLoader />);
	}
	return (<SigninProvider>
	<InsideLogin />
	</SigninProvider>);

	}
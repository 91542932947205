
import {useState,useEffect} from 'react';
import { useSearchParams } from 'react-router-dom';

import {url} from "../../common/shared";

import Notification from '../../common/Notification';

import { useLoaderData, useNavigation, Link } from "react-router-dom";
import SiteLoader from '../../common/SiteLoader';

export default function Verify(){

	const [searchParams] = useSearchParams();
    console.log(searchParams); // ▶ URLSearchParams {}

    

const [notice,setNotice]=useState(undefined);
    
	useEffect(() => {
		if (searchParams) {
	    const currentParams = Object.fromEntries([...searchParams]);
	    console.log(currentParams); // get new values onchange


	    const formData = new FormData();
    	Object.keys(currentParams).forEach((e)=>{
    		formData.append(e, currentParams[e]);
    	});

	    //?ver_code=&user=17

	    fetch(`${url}/api/user.php?crud=verifyuser`, {
	      method: "POST",
	      body:formData
	    }).then((res) => res.json()).then((a)=>{
	    	let val={data:a};
	    	if ( val.data.error==false && val.data.notice=='successful' ){
		        // 1. Verified

		        setNotice({...val.data,type: 'success'});
	 			setTimeout(()=>{
	                window.location.href="login";
	            },4000);
		    }
		    else if ( val.data.error==true && val.data.notice=='wrong' ){
		        // 2. Wrong VerCode AND 3. Already Verified
		        
		        setNotice({...val.data,type: 'error'});
		    }
		    else if ( val.data.error==true && val.data.notice=='noaccount' ){
		        // 3. Error - Account Not Found
		        
		        setNotice({...val.data,type: 'error'});
		    } 
	    }).catch((e)=>{
	    	setNotice({type:'error',title:'signup error','message':e.message});
	    });
	}



    }, [searchParams]);

	return (<>
		<Notification newnotice={notice} cb={()=>{
			//alert("good");
		}} />
		</>);
}
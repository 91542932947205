import React, { useState, useEffect } from "react";

//import { classlist, api, slugify } from '../data';

import {url,admin} from "../../../../common/shared";
import { useNavigate } from "react-router-dom";

import { useSearchParams } from 'react-router-dom';

//import { apiBase, adminBase, nonAuth, loginpath } from "./config";
//import { nonAuth } from "../routr";

//import Swal from "sweetalert2";
//import withReactContent from "sweetalert2-react-content";
//const MySwal = withReactContent(Swal);

const ResetCtx = React.createContext();
/*https://app.cryptotradeoptions.net/dashboard*/

export const useResetCtx = () => React.useContext(ResetCtx);

export const ResetProvider = ({ children }) => {
    //house crud methods


    const [params,setParams]=useState({});

	const [searchParams] = useSearchParams();
    console.log(searchParams); // ▶ URLSearchParams {}

    useEffect(() => {
		if (searchParams) {
		    const currentParams = Object.fromEntries([...searchParams]);
		    console.log(currentParams); // get new values onchange

		    const {email,reset_code}=currentParams;
		    setParams({...currentParams}); 

		    /*const formData = new FormData();
	    	Object.keys(currentParams).forEach((e)=>{
	    		formData.append(e, currentParams[e]);
	    	});*/
	    }
    },[]);


    const [disabled,setDisabled]=useState(true);
    const [loading,setLoading]=useState(false);
    //notice

    const [da,setDa]=useState({});

    const [errors,setE]=useState({ 
password:"should not be empty",conf_pwd:"required"});

    const validateConditions=(k,v,rules)=>{
    	//Object.keys(rules).forEach((rule)=>{

    		//console.log(rule);
    		let rule=Object.keys(rules)[0];
    		let rulev=rules[rule];



	    	if (rule=="min") {
	    		console.log(`${v?.length}<${rulev}`)
	    		if (v?.length<rulev) {
	    			console.log("less");
	    			setE({...errors,[k]:`${k} must be more than ${rules.min} characters.`});
	    		}else{
	    			let y={...errors};
	    			delete y[k];
	    			setE({...y});
	    		}
	    	}else if (rule=="max") {
	    		if (v?.length>rules[rule]) {
	    			setE({...errors,[k]:`${k} must not be more than ${rules.max} characters.`});
	    		}else{
	    			let y={...errors};
	    			delete y[k];
	    			setE({...y});
	    		}
	    	}else if (rule=="validEmail") {
	    		var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  				if (v?.match(validRegex)) {
  					let y={...errors};
	    			delete y[k];
	    			setE({...y});
  				}else{
  					setE({...errors,[k]:"Invalid Email"});
  				}
	    	} 
 
    //})
    }

    const changeSomething=(k,v)=>{ 
    	setDa({...da,[k]:v}); 
    	if (k=="password") {
    		validateConditions(k,v,{min:4});
    	}
    }
    
    useEffect(()=>{
    	console.log(errors);
    	if (Object.keys(errors).length==0) {
    		setDisabled(false);
    	}else{
    		setDisabled(true);
    	}
    },[errors]);

    const confirmPass=(k,v)=>{ 
    	//let k=
    	if (da?.password===v) {
    		let y={...errors};
			delete y[k];
			setE({...y});
		}else{
			setE({...errors,[k]:"Password mismatch"});
		}
    }

    const [notice,setNotice]=useState(undefined);
    const resetPwd=()=>{
    	let r={...da,action:"reset",new_password:da.password,...params};
    	const formData = new FormData();
    	Object.keys(r).forEach((e)=>{
    		formData.append(e, r[e]);
    	});

    	fetch(`${url}/api/user.php?crud=userforgot`, {
	      method: "POST",
	      body:formData
	    }).then((res) => res.json()).then((a)=>{
	    	setNotice({...a});
	        /*if (a.type=="success") {
	            setTimeout(()=>{
	                window.location.href="login";
	            },4000);
	        }*/
	    }).catch((e)=>{
	    	setNotice({type:'error',title:'reset error','message':e.message});
	    })
    }

    return (
        <ResetCtx.Provider
            value={{ notice,setNotice,
            	changeSomething,disabled,loading,resetPwd,confirmPass
            }}
        >
            {children}
        </ResetCtx.Provider>
    );
};

import React, { useState, useEffect } from "react";

//import { classlist, api, slugify } from '../data';

import {url} from "../../../common/shared";
import { useNavigate } from "react-router-dom";
//import { apiBase, adminBase, nonAuth, loginpath } from "./config";
//import { nonAuth } from "../routr";

//import Swal from "sweetalert2";
//import withReactContent from "sweetalert2-react-content";
//const MySwal = withReactContent(Swal);

const DepositCtx = React.createContext();
/*https://app.cryptotradeoptions.net/dashboard*/

export const useDepositCtx = () => React.useContext(DepositCtx);

export const DepositProvider = ({ children }) => {
    //house crud methods

    const [da,setDa]=useState({});
    const [disabled,setDisabled]=useState(true);
    const [loading,setLoading]=useState(false);

    /*
        fullname:"should not be empty" ,
        email:"should not be empty",
        phone:"should not be empty"*/
    const [errors,setE]=useState({});

    const validateConditions=(k,v,rules)=>{
    	//Object.keys(rules).forEach((rule)=>{

    		//console.log(rule);
    		let rule=Object.keys(rules)[0];
    		let rulev=rules[rule];



	    	if (rule=="min") {
	    		console.log(`${v?.length}<${rulev}`)
	    		if (v?.length<rulev) {
	    			console.log("less");
	    			setE({...errors,[k]:`${k} must be more than ${rules.min} characters.`});
	    		}else{
	    			let y={...errors};
	    			delete y[k];
	    			setE({...y});
	    		}
	    	}else if (rule=="max") {
	    		if (v?.length>rules[rule]) {
	    			setE({...errors,[k]:`${k} must not be more than ${rules.max} characters.`});
	    		}else{
	    			let y={...errors};
	    			delete y[k];
	    			setE({...y});
	    		}
	    	}else if (rule=="validEmail") {
	    		var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  				if (v?.match(validRegex)) {
  					let y={...errors};
	    			delete y[k];
	    			setE({...y});
  				}else{
  					setE({...errors,[k]:"Invalid Email"});
  				}
	    	} 
 
    //})
    }

    const changeSomething=(k,v)=>{ 
    	setDa({...da,[k]:v});
 
    	if (k=="fullname") {
    		//validateConditions(k,v,{min:5,max:10});
    		validateConditions(k,v,{min:5});
    		//validateConditions(k,v,{max:10});
    	}
        if (k=="email") {
            validateConditions(k,v,{validEmail:true});
        } 
    	if (k=="phone") {
    		validateConditions(k,v,{min:7});
    		//validateConditions(k,v,{max:20});
    		//validateConditions(k,v,{min:5,max:20});
    	}  
    }
    //loading
    useEffect(()=>{
    	console.log(errors);
    	if (Object.keys(errors).length==0) {
    		setDisabled(false);
    	}else{
    		setDisabled(true);
    	}
    },[errors]); 

    const [done,setD]=useState(false);

    const [oldData,sOldData]=useState([]);  
    const [depositAddyList,sDepositList]=useState([]);

 


    const [selectedMethod,setSelectedM]=useState();
    const changeSelectedMethod=(i)=>{
        console.log(i);
        setSelectedM(depositAddyList?.[i]);
    }

    //addrs
    const getdepositaddr=()=>{
        //let url="";

        let r=new FormData();
        r.append("get_deposit_addr","get_deposit_addr");
        r.append("token",localStorage.getItem("token",null));

        fetch(`${url}/api/user.php`, {
      method: "POST",
      body: r
    }).then((res) => res.json()).then((c)=>{
        setD(true);
        sDepositList(c?.addrs); 
        setSelectedM(c?.addrs[0]);
    }).catch((e)=>{
        console.log(e);
    })
    }




    const getmydeposit=()=>{
        //let url="";
        let r=new FormData();
        r.append("my_deposits","my_deposits");
        r.append("token",localStorage.getItem("token",null));


        fetch(`${url}/api/user.php`, {
          method: "POST",
          body: r
        }).then((res) => res.json()).then((c)=>{
            setD(true);
            console.log(c);
            sOldData(c?.my_deposits);
                /*if (c?.status) {
                    
                }*/
        }).catch((e)=>{
            console.log(e);
        });
    }


    useEffect(()=>{
        let y=localStorage.getItem("token");
        if (y==null) {
            window.location.href="";
        }else{ 
            getmydeposit();
            getdepositaddr();
        }
    },[]);

    //amount

    const [notice,setNotice]=useState(undefined);
    const [amount,setAmount]=useState(undefined);
    const [depositProof,setDepositProof]=useState(undefined);

    const completeDeposit=()=>{
        //completeDeposit

        let r=new FormData();
        r.append("depositmethod","Crypto");
        r.append("amount",amount);
        r.append("depositProof",depositProof);
        r.append("token",localStorage.getItem("token",null));
 

        fetch(`${url}/api/user.php?crud=newdeposit`, {
          method: "POST",
          body: r
        }).then((res) => res.json()).then((c)=>{
            console.log(c); 
            setNotice(c);

        }).catch((e)=>{
            
        });

    }


    return (
        <DepositCtx.Provider
            value={{notice,setNotice,
                depositAddyList,amount,setAmount,setDepositProof,
                done,oldData,selectedMethod,changeSelectedMethod,
                disabled,loading,completeDeposit,
                changeSomething,
                errors,getdepositaddr
            }}
        >
            {children}
        </DepositCtx.Provider>
    );
};

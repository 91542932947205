
import LoInput from '../../../common/LoInput';
import LoButton,{LoLink} from '../../../common/LoButton';
import Pair from '../../../common/Pair';

import { useLoaderData, useNavigation, Link } from "react-router-dom";
import SiteLoader from '../../../common/SiteLoader';

import {url,admin} from "../../../common/shared";
import {ResetProvider,useResetCtx} from './context';
//Pair
import st from '../fo.module.scss';

import Notification from '../../../common/Notification';

export  function InsideReset(){


	const {changeSomething,disabled,loading,resetPwd,notice,confirmPass}=useResetCtx();

	return (<div className={`${st.con} content`}>


		<Notification newnotice={notice} cb={()=>{ 

			/*if (!loginres?.error) {
			if (loginres?.jwt) { 
	            window.location.href="dashboard";
	        }else{
	            window.location.href=admin;
	        }}*/
	        window.location.href="login";

		}} />

		<div className={st.img}>
		<img src="/wp-user/site/logo.png" />
		</div>

		<div className={st.d1}>New Password </div>
		<div className={st.d2}> Create a strong password for your account  </div>

		
<LoInput  onChange={(e)=>{
	changeSomething('password',e.target.value);
}}   icon="key" type="password" label="" placeholder="New Password " />
{/*errors?.password?<p>{errors?.password}</p>:null*/}
<LoInput  onChange={(e)=>{
	confirmPass('conf_pwd',e.target.value);
}}   icon="key" type="password" label="" placeholder="Confirm Password " />
{/*errors?.conf_pwd?<p>{errors?.conf_pwd}</p>:null*/}


<p>&nbsp;</p>
		<LoButton color="red" text="Reset Password" loading={loading} disabled={disabled} onClick={()=>{
	resetPwd();
}}/>
		</div>);
}


export default function Reset0(){
	const result = useLoaderData();
	const navigation = useNavigation(); 
	if (navigation.state === "loading") {
	    return (<SiteLoader />);
	}
	return (<ResetProvider>
		<InsideReset />
	</ResetProvider>);

}
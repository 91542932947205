import s from './profile.module.scss';
import i from '../../common/icons/i.module.scss';
import LoButton,{LoLink} from '../../common/LoButton';
//ShadowBox
import Pair2 from '../../common/Pair2';
import ShadowBox from '../../common/ShadowBox';
import Footer from '../../common/Footer';
//Footer

import {LetterIcon,LetterIconSmall} from '../../common/LetterIcon';
import { useLoaderData, useNavigation, Link } from "react-router-dom";
import SiteLoader from '../../common/SiteLoader';

import {userurl,url} from '../../common/shared';
import {LoInput2} from '../../common/LoInput';
import {useProfileCtx,ProfileProvider} from './context';

import Notification from '../../common/Notification';

export function InsideProfile(){

	const {oldData,errors,notice,done,disabled,loading,updatepro,getpro,changeSomething}=useProfileCtx();
	return (<>{done?<div className={`${s.con} content`}>

<Notification newnotice={notice} cb={()=>{ 
			window.location.reload();
		}} />

<div className={s.nav}> 
<div className={i.right} onClick={()=>{
	window.location.href=`${userurl}/account`;
}}></div>
<div > Profile Settings</div>
<div  ></div>

</div>

<div className={s.ibox2}>
<LetterIcon user={oldData} />
{/*<div className={s.imgbox}>
<img src={oldData?.picture?`${url}/wp-user/uploads/${oldData?.picture}`:"photos/dp.svg"} />
				
</div>*/}{/*
<img src={oldData?.picture?oldData?.picture:"photos/dp.svg"} />*/}
</div>

<Pair2 title={oldData?.username} subtitle={`Customer ID : ${oldData?.customer_id}`} />

<ShadowBox text="Leave a field unchanged to retain its value." />

<LoInput2 onChange={(e)=>{
	changeSomething('fullname',e.target.value);
}} setValue={oldData?.fullname} label="Full Name *" />
<LoInput2 onChange={(e)=>{
	changeSomething('email',e.target.value);
}} setValue={oldData?.email} label="Email *" />
<LoInput2 onChange={(e)=>{
	changeSomething('phone',e.target.value);
}} setValue={oldData?.phone} label="Number *" />

<p>&nbsp;</p>
		<LoButton disabled={disabled} onClick={()=>{
			updatepro();
		}} loading={loading} color="red" text="Update Profile" />


<Footer />
		</div>:null}</>
		);
}

export default function Profile(){
	const result = useLoaderData();
	const navigation = useNavigation(); 
	if (navigation.state === "loading") {
	    return (<SiteLoader />);
	}
	
	return (<ProfileProvider><InsideProfile /></ProfileProvider>);
}
import React, { useState, useEffect } from "react";

//import { classlist, api, slugify } from '../data';

import {url,admin} from "../../../common/shared";
import { useNavigate } from "react-router-dom";
//import { apiBase, adminBase, nonAuth, loginpath } from "./config";
//import { nonAuth } from "../routr";

//import Swal from "sweetalert2";
//import withReactContent from "sweetalert2-react-content";
//const MySwal = withReactContent(Swal);

const SigninCtx = React.createContext();
/*https://app.cryptotradeoptions.net/dashboard*/

export const useSigninCtx = () => React.useContext(SigninCtx);

export const SigninProvider = ({ children }) => {
    //house crud methods

    const [da,setDa]=useState({});
    const [disabled,setDisabled]=useState(true);
    const [loading,setLoading]=useState(false);

    const [siteName,ssN]=useState(window['site_name']);
    useEffect(()=>{
        ssN(window['site_name']);
    },[]);

    const [errors,setE]=useState({username:"should not be empty" 
,password:"should not be empty"});

    const validateConditions=(k,v,rules)=>{
    	//Object.keys(rules).forEach((rule)=>{

    		//console.log(rule);
    		let rule=Object.keys(rules)[0];
    		let rulev=rules[rule];



	    	if (rule=="min") {
	    		console.log(`${v?.length}<${rulev}`)
	    		if (v?.length<rulev) {
	    			console.log("less");
	    			setE({...errors,[k]:`${k} must be more than ${rules.min} characters.`});
	    		}else{
	    			let y={...errors};
	    			delete y[k];
	    			setE({...y});
	    		}
	    	}else if (rule=="max") {
	    		if (v?.length>rules[rule]) {
	    			setE({...errors,[k]:`${k} must not be more than ${rules.max} characters.`});
	    		}else{
	    			let y={...errors};
	    			delete y[k];
	    			setE({...y});
	    		}
	    	}else if (rule=="validEmail") {
	    		var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  				if (v?.match(validRegex)) {
  					let y={...errors};
	    			delete y[k];
	    			setE({...y});
  				}else{
  					setE({...errors,[k]:"Invalid Email"});
  				}
	    	} 
 
    //})
    }

    const changeSomething=(k,v)=>{ 
    	setDa({...da,[k]:v});
 
    	if (k=="username") {
    		//validateConditions(k,v,{min:5,max:10});
    		validateConditions(k,v,{min:3});
    		//validateConditions(k,v,{max:10});
    	} 
    	if (k=="password") {
    		validateConditions(k,v,{min:4});
    		//validateConditions(k,v,{max:20});
    		//validateConditions(k,v,{min:5,max:20});
    	}  
    }
    //loading
    useEffect(()=>{
    	console.log(errors);
    	if (Object.keys(errors).length==0) {
    		setDisabled(false);
    	}else{
    		setDisabled(true);
    	}
    },[errors]); 

    const [notice,setNotice]=useState(undefined);
    const [loginres,setloginres]=useState(undefined);

    const signin=()=>{
    	//let url="";
        const formData = new FormData();
        Object.keys(da).forEach((e)=>{
            formData.append(e, da[e]);
        });

    	fetch(`${url}/api/user.php?crud=userlogin`, {
      method: "POST",
      body: formData
    }).then((res) => res.json()).then((c)=>{
        console.log(c);
        setloginres(c);
        setNotice(c);
        if (c?.jwt) {
            localStorage.setItem("token", c?.jwt);
            localStorage.setItem("newlogin", true);
            //window.location.href="dashboard";
        }else{
            //window.location.href=admin;
        }
    }).catch((e)=>{

    });
    }

    return (
        <SigninCtx.Provider
            value={{
                   loginres,notice,siteName,
                disabled,loading,
                changeSomething,
                errors,signin
            }}
        >
            {children}
        </SigninCtx.Provider>
    );
};


import sty from './st.module.scss';

export default function LoButton({color,text,onClick,disabled,loading=false}){
	return (<button className={`${sty.lob} ${disabled?sty.grey:sty[color]}`} disabled={disabled} onClick={onClick}>
		<>{loading?"":text}</>
		</button >); 
}
export  function LoLink({color,text,href}){
	return (<a className={`${sty.lob2} ${sty[color]}`} href={href} >
		{text}
		</a >); 
}
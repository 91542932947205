import React, { useState, useEffect } from "react";

//import { classlist, api, slugify } from '../data';

import {url} from "../../../common/shared";
import { useNavigate } from "react-router-dom";
//import { apiBase, adminBase, nonAuth, loginpath } from "./config";
//import { nonAuth } from "../routr";

//import Swal from "sweetalert2";
//import withReactContent from "sweetalert2-react-content";
//const MySwal = withReactContent(Swal);

const SignupCtx = React.createContext();
/*https://app.cryptotradeoptions.net/dashboard*/

export const useSignupCtx = () => React.useContext(SignupCtx);

export const SignupProvider = ({ children }) => {
    //house crud methods

    const [da,setDa]=useState({});
    const [disabled,setDisabled]=useState(true);
    const [loading,setLoading]=useState(false);
    const [terms,setTerms]=useState(false);

    const [errors,setE]=useState({fullname:"provide fullname",
    	username:"should not be empty"
,email:"should not be empty"
,password:"should not be empty"
,phone:"should not be empty",country:"required",conf_pwd:"required",terms:"required"});


    useEffect(()=>{
        let k='terms';
        if (terms) {
            let y={...errors}; 
            delete y[k];
            setE({...y});
        }else{
            let y={...errors}; 
            y[k]="required";
            setE({...y});
        }
        //changeSomething()
    },[terms]);
    const validateConditions=(k,v,rules)=>{
    	//Object.keys(rules).forEach((rule)=>{

    		//console.log(rule);
    		let rule=Object.keys(rules)[0];
    		let rulev=rules[rule];



	    	if (rule=="min") {
	    		console.log(`${v?.length}<${rulev}`)
	    		if (v?.length<rulev) {
	    			console.log("less");
	    			setE({...errors,[k]:`${k} must be more than ${rules.min} characters.`});
	    		}else{
	    			let y={...errors};
	    			delete y[k];
	    			setE({...y});
	    		}
	    	}else if (rule=="max") {
	    		if (v?.length>rules[rule]) {
	    			setE({...errors,[k]:`${k} must not be more than ${rules.max} characters.`});
	    		}else{
	    			let y={...errors};
	    			delete y[k];
	    			setE({...y});
	    		}
	    	}else if (rule=="validEmail") {
	    		var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  				if (v?.match(validRegex)) {
  					let y={...errors};
	    			delete y[k];
	    			setE({...y});
  				}else{
  					setE({...errors,[k]:"Invalid Email"});
  				}
	    	} 
 
    //})
    }

    const changeSomething=(k,v)=>{ 
    	setDa({...da,[k]:v});

    	if (k=="country" && v!="") {
    		let y={...errors};
			delete y[k];
			setE({...y});
		}else{
			setE({...errors,[k]:"country required"});
		}
    	//fullname
    	if (k=="fullname") {
    		validateConditions(k,v,{min:3});
    		//validateConditions(k,v,{max:20});
    	}
    	if (k=="username") {
    		//validateConditions(k,v,{min:5,max:10});
    		validateConditions(k,v,{min:3});
    		//validateConditions(k,v,{max:10});
    	}
    	if (k=="email") {
    		validateConditions(k,v,{validEmail:true});
    	}
    	if (k=="password") {
    		validateConditions(k,v,{min:4});
    		//validateConditions(k,v,{max:20});
    		//validateConditions(k,v,{min:5,max:20});
    	} 
    	if (k=="phone") {
    		//validateConditions(k,v,{min:7,max:15});
    		validateConditions(k,v,{min:7});
    		//validateConditions(k,v,{max:15});
    	} 
    }
    //loading
    useEffect(()=>{
    	console.log(errors);
    	if (Object.keys(errors).length==0) {
    		setDisabled(false);
    	}else{
    		setDisabled(true);
    	}
    },[errors]);
    const confirmPass=(k,v)=>{ 
    	//let k=
    	if (da?.password===v) {
    		let y={...errors};
			delete y[k];
			setE({...y});
		}else{
			setE({...errors,[k]:"Password mismatch"});
		}
    }

    const [notice,setNotice]=useState(undefined);
    /*JSON.stringify({
        //token: token,
        ...da,
      })*/
    const signup=()=>{

        if (disabled) {
            if (errors.terms) { 
                setNotice({type:'error','message':"Accept Terms and Conditions"});
            }
        }else{


    	const formData = new FormData();
    	Object.keys(da).forEach((e)=>{
    		formData.append(e, da[e]);
    	});

    	//let url="";
    	fetch(`${url}/api/user.php?crud=newuser`, {
      method: "POST",
      body:formData
    }).then((res) => res.json()).then((a)=>{
    	setNotice({...a});
        if (a.type=="success") {
            setTimeout(()=>{
                window.location.href="login";
            },4000);
        }
    }).catch((e)=>{
    	setNotice({type:'error',title:'signup error','message':e.message});
    })
}
    }

    return (
        <SignupCtx.Provider
            value={{
                confirmPass,
                disabled,loading,
                changeSomething,
                errors,signup,
                notice,terms,setTerms
            }}
        >
            {children}
        </SignupCtx.Provider>
    );
};

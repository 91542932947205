import {getFlag} from './getflag';

/*
export const url="http://localhost:8200/logistics2";
export const userurl="http://localhost:3000";
export const admin="http://localhost:8200/logistics2/wp-admin/dashboard.php";
*/

/*
export const url="https://meta-ssetstrades.live/";
export const userurl="https://meta-ssetstrades.live/wp-user";
export const admin="https://meta-ssetstrades.live/wp-admin/dashboard.php";

//speedway-logistics.online
*/


export const url="https://speedway-logistics.online/";
export const userurl="https://speedway-logistics.online/wp-user";
export const admin="https://speedway-logistics.online/wp-admin/dashboard.php";


export const GetFlag=getFlag;

//export const userurl="http://localhost:3000/wp-user"; 

export function FormatCurrency(value) {
  //value
  if (value) {
    let val  = `${value}`
    let p1 = new Intl.NumberFormat("en-US").format(value).split(".")[0]
    let p2 = parseFloat(val).toFixed(2).split(".")[1]
    let p3 = `${p1}.${p2}`
    return p3
  } else {
    let p1 = new Intl.NumberFormat("en-US").format(0).split(".")[0]
    let p2 = parseFloat("0").toFixed(2).split(".")[1]
    let p3 = `${p1}.${p2}`
    return p3
  }
  /*
	{new Intl.NumberFormat('en-US').format(myData?.tb?.current_balance).split('.')[0]}
                                                                .{parseFloat(myData?.tb?.current_balance).toFixed(2).split('.')[1]}*/
}

import LoInput from '../../common/LoInput';
import LoButton,{LoLink} from '../../common/LoButton';
import Pair from '../../common/Pair';

import { useLoaderData, useNavigation, Link } from "react-router-dom";
import SiteLoader from '../../common/SiteLoader';

import {url,admin} from "../../common/shared";
import {ForgotProvider,useForgotCtx} from './context';
//Pair
import st from './fo.module.scss';

import Notification from '../../common/Notification';

export  function InsideForgot(){

	const {changeSomething,disabled,loading,reqReset,notice}=useForgotCtx();

	return (<div className={`${st.con} content`}>


		<Notification newnotice={notice} cb={()=>{ 

			/*if (!loginres?.error) {
			if (loginres?.jwt) { 
	            window.location.href="dashboard";
	        }else{
	            window.location.href=admin;
	        }}*/

		}} />

		<div className={st.img}>
		<img src="site/logo.png" />
		</div>

		<div className={st.d1}>Reset Password to your account</div>
		<div className={st.d2}> Have an account? <a href="login">Sign In here</a> </div>


<LoInput icon="email" onChange={(e)=>{
	changeSomething('email',e.target.value);
}}  label="" placeholder="Enter Email " />

<p>&nbsp;</p>
		<LoButton color="red" text="Reset Password" loading={loading} disabled={disabled} onClick={()=>{
	reqReset();
}}/>

		</div>);
}


export default function Forgot(){
	const result = useLoaderData();
	const navigation = useNavigation(); 
	if (navigation.state === "loading") {
	    return (<SiteLoader />);
	}
	return (<ForgotProvider>
		<InsideForgot />
	</ForgotProvider>);

}
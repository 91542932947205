import s from './account.module.scss';
import i from '../../common/icons/i.module.scss';
import LoButton,{LoLink} from '../../common/LoButton';
//ShadowBox

import { useLoaderData, useNavigation, Link } from "react-router-dom";
import SiteLoader from '../../common/SiteLoader';


import {LetterIcon,LetterIconSmall} from '../../common/LetterIcon';
import {userurl,url} from '../../common/shared';
import Pair2 from '../../common/Pair2';
import ShadowBox from '../../common/ShadowBox';
import Footer from '../../common/Footer';
//Footer
import {useProfileCtx,ProfileProvider} from './context';
import {LoInput2} from '../../common/LoInput';

export default function Account(){
	const result = useLoaderData();
	const navigation = useNavigation(); 
	if (navigation.state === "loading") {
	    return (<SiteLoader />);
	}
	return (<ProfileProvider><IAccount /></ProfileProvider>);
}

export  function IAccount(){
	const {oldData,errors,notice,done,disabled,loading,updatepro,getpro,changeSomething}=useProfileCtx();
	
	return (<div className={`${s.con} content`}>

<div className={s.nav}> 
<div className={i.right}  onClick={()=>{
	window.location.href=`${userurl}/dashboard`;
}}></div>
<div > Account Settings</div>
<div  ></div>

</div>




<div className={s.ibox2}>
<LetterIcon user={oldData} onClick={()=>{

}} />{/*
<div className={s.imgbox}><img src={oldData?.picture?`${url}/wp-user/uploads/${oldData?.picture}`:"photos/dp.svg"} />
</div>
*/}
</div>

<Pair2 title={oldData?.username} subtitle={`Customer ID : ${oldData?.customer_id}`} />

<LinkBox icon="person" title="Account Information" 
subtitle="Update your account information" href="profile" />


<LinkBox icon="shield2" title="Reset Password" 
subtitle="Protect & Secure Your Account  " href="reset" />


<LinkBox icon="speaker" title="Help & Support " 
subtitle="Get support to help you with any issue and inquiry " href={`mailto:${window['support']}`} />


<LinkBox icon="logout" title="Log Out " 
subtitle="" href={null} onClick={()=>{
	localStorage.setItem("token", null);
	window.location.href="/";
}}/>

<p>&nbsp;</p>
		 

<Footer />
		</div>
		);
}

function LinkBox({icon,title,subtitle,href,onClick}){
	return (<a href={href} className={s.linkbox} onClick={onClick}>
		<div className={s.b1}> 
			<div className={i[icon]}></div>
		</div>
		<div className={s.b2}> 
			<div className={s.b2h1}>{title}</div>
			<div className={s.b2h2}>{subtitle}</div>
		</div>
	</a>);
}
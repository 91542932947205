import lo from './los.module.scss';
import {useRef,useState,useEffect} from 'react';

import i from '../icons/i.module.scss';

export  function LoSelect2({icon,label,onChange,placeholder,children}){
	const s=useRef();
	return (<div className={lo.gi2}>
			<label>{label}</label>
			<div className={lo.ig}>
				<div className={i?.[icon]}>
				</div>
				<select ref={s} onChange={onChange}>
				<option value="">{placeholder}</option>
				{children}
				</select>
			</div>
		</div>);
}
 

export default function LoSelect({label,placeholder,onChange,children}){
	const s=useRef();
	return (<div className={lo.los}>
			<label>{label}</label>
			<div className={lo.scon}>
				<select ref={s} onChange={onChange}>
				<option>{placeholder}</option>
				{children}
				</select>
			</div>
		</div>);
}

export  function LoSelectFile({label,onChange}){
	const s=useRef();
	const [fileselectionstate,sfss]=useState("No file selected");
	return (<>
			<div className={lo.lsf}>
			<input type="file" ref={s} accept="image/*" onChange={(e)=>{
				sfss("File selected");
				onChange(e);
				e.target.value=null
			}} />
				<label>{label}</label>
				<div className={lo.sfcon}>
					<div className={lo.p1} onClick={()=>{
						s.current.click();
					}}>Choose File</div>
					<div className={lo.p2} >{fileselectionstate}</div>
				</div>
			</div>
		</>);
}
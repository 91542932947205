import sj from './sb.module.scss';
import {useState,useEffect} from 'react';

export default function Footer({}){

    const [siteName,ssN]=useState(window['fsite_name']);
    useEffect(()=>{
        ssN(window['fsite_name']);
    },[]);
	return (<div className={sj.box}><div>© {window['fsite_name']}.</div></div>);
}
import sty from './dep.module.scss';

import { useLoaderData, useNavigation, Link } from "react-router-dom";
import SiteLoader from '../../common/SiteLoader';

import i from '../../common/icons/i.module.scss';
import {useState} from 'react';
import LoSelect,{LoSelectFile} from '../../common/LoSelect'
import CopyBox from '../../common/CopyBox';
//LoInput3
import {userurl,FormatCurrency} from '../../common/shared';
import Notification from '../../common/Notification';
import ShortNotification from '../../common/ShortNotification';
import {LoInput3} from '../../common/LoInput';
import LoButton from '../../common/LoButton';
//LoButton
import {DepositProvider,useDepositCtx} from './context';

export function InsideDeposit(){

	//const [notice,setNotice]=useState(undefined);


	const [notice2,setNotice2]=useState(undefined);
	const {done,depositAddyList,oldData,setAmount,setDepositProof,notice,setNotice,completeDeposit,selectedMethod,changeSelectedMethod}=useDepositCtx();
	return (<>{done?<div className={`${sty.con} content`}>

		<ShortNotification newnotice={notice2} cb={()=>{
			//alert("good");
			//window.location.reload();
		}} />
		<Notification newnotice={notice} cb={()=>{
			//alert("good");
			window.location.reload();
		}} />

<div className={sty.nav}> 
<div className={i.right} onClick={()=>{
	window.location.href=`${userurl}/dashboard`;
}}></div>
<div > Deposit Funds </div>
<div  ></div>

</div> 

<div className={sty.t2}>
<LoSelect label="Choose Method *"  placeholder="--select method--" onChange={(e)=>{
	changeSelectedMethod(e.target.value);
}} >
<>{depositAddyList?.map((s,i)=>{
	return (<option key={i} value={i}>{s?.name}</option>)
})}</>
</LoSelect>
</div>

<div className={sty.addressCon}>
<>{selectedMethod?<>
<div className={sty.address}>{selectedMethod?.asset} Address</div>
<CopyBox text={selectedMethod?.address} />
<CopyBox text="Tap To Copy Address " onClick={()=>{
	navigator.clipboard.writeText(selectedMethod?.address).then(()=> {
		setNotice2({type:'success',message:'Copied Succesfully'}); 
	}, function(err) {
		setNotice2({type:'error',message:'Copy Failed'});  
	});
	
}} /></>:null}</>
</div>


<div className={sty.subpayCon}>
<div className={sty.subpay}>Submit Payment</div>

<LoInput3 labelClass={sty.labelSty} label="Enter Amount * " onChange={(e)=>{
	setAmount(e.target.value);
}} />
<LoSelectFile label="Payment Proof *" onChange={(e)=>{
	console.log(e);
	setDepositProof(e.target.files[0]);
}} />
<br/>
<LoButton color="red" text="Submit" onClick={()=>{
	completeDeposit();
}} />

</div>

{oldData?.map((d,i)=>{
	return (<DepositItem data={d} key={i} />);
})} 

</div>:null}</>);
}

function DepositItem({data}){
	return (<div className={sty.deposits}>
		<div className={i.plus2}>
		</div>

		<div >
		<div className={sty.h3}>Type </div>
		<div className={sty.p3}>Crypto </div>
		</div>

		<div >
		<div className={sty.h3}>Amount </div>
		<div className={sty.p3}>${FormatCurrency(data?.amount)} </div>
		</div>

		<div >
		<div className={sty.h3}>Date </div>
		<div className={sty.p3}>{data?.date} </div>
		</div>

		<div >
		</div>

		<div className={`${sty.status} ${sty[data?.status.toLowerCase()]}`}>
		{data?.status}
		</div>
	</div>);
}


export default function Deposit(){
	const result = useLoaderData();
	const navigation = useNavigation(); 
	if (navigation.state === "loading") {
	    return (<SiteLoader />);
	}
	return (<DepositProvider>
	<InsideDeposit />
	</DepositProvider>);
}


import t from './trac.module.scss';

import i from '../../common/icons/i.module.scss';
import EnterBox from './popup';
import {TrackProvider,useTrackCtx} from './context';
import {ShipmentItem,HistoryItem} from '../dashboard';
import t2 from '../dashboard/das.module.scss';

import { useLoaderData, useNavigation, Link } from "react-router-dom";
import SiteLoader from '../../common/SiteLoader';

import Notification from '../../common/Notification';
import {LetterIcon,LetterIconSmall} from '../../common/LetterIcon';

import Footer from '../../common/Footer';

export  function InsideTrack(){
	const {getD,item,notice}=useTrackCtx();
	return (<>

		<Notification newnotice={notice} cb={()=>{
			//alert("good");
		}} />

		{(item?.active || item?.other)?null:<>
	<div className={t.shieldcon}>
		<div className={t.shield}>
		</div>
	</div><EnterBox /><div className={t.trbanner}>
	<img src="photos/tr.svg" />
	</div></>}

		{item?.active?<>
<div className={t.nav}> 
<div className={i.right}  onClick={()=>{
	window.location.reload();
}}></div>
<div > Your Shipment</div>
<div  ></div>

</div><ShipmentItem item={item?.active} />
	<div className={t.shieldcon}>
		<div className={t.shield}>
		</div>
	</div>
<Footer /></>:null}
		{item?.other?<>
<div className={t.nav}> 
<div className={i.right}  onClick={()=>{
	window.location.reload();
}}></div>
<div > Your Shipment</div>
<div  ></div>

</div><HistoryItem item={item?.other} />
	<div className={t.shieldcon}>
		<div className={t.shield}>
		</div>
	</div><Footer /></>:null}

		</>);
}

export default function Track(){

	const result = useLoaderData();
	const navigation = useNavigation(); 
	if (navigation.state === "loading") {
	    return (<SiteLoader />);
	}
	return (<div className={`${t.con} ${t2.con} content`}>

{/*
	<LetterIconSmall />*/}

<TrackProvider>
	<InsideTrack />
</TrackProvider>

	

	</div>);
}
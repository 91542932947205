import React, { useState, useEffect } from "react";

//import { classlist, api, slugify } from '../data';

import {url} from "../../../common/shared";
import { useNavigate } from "react-router-dom";
//import { apiBase, adminBase, nonAuth, loginpath } from "./config";
//import { nonAuth } from "../routr";

//import Swal from "sweetalert2";
//import withReactContent from "sweetalert2-react-content";
//const MySwal = withReactContent(Swal);

const ResetCtx = React.createContext();
/*https://app.cryptotradeoptions.net/dashboard*/

export const useResetCtx = () => React.useContext(ResetCtx);

export const ResetProvider = ({ children }) => {
    //house crud methods


    const [notice,setNotice]=useState(undefined);

    const [da,setDa]=useState({});
    const [disabled,setDisabled]=useState(true);
    const [loading,setLoading]=useState(false);

    const [errors,setE]=useState({
        c_pass:"should not be empty" ,
        n_pass:"should not be empty",
        conf_pass:"should not be empty"});

    const validateConditions=(k,v,rules)=>{
    	//Object.keys(rules).forEach((rule)=>{

    		//console.log(rule);
    		let rule=Object.keys(rules)[0];
    		let rulev=rules[rule];



	    	if (rule=="min") {
	    		console.log(`${v?.length}<${rulev}`)
	    		if (v?.length<rulev) {
	    			console.log("less");
	    			setE({...errors,[k]:`${k} must be more than ${rules.min} characters.`});
	    		}else{
	    			let y={...errors};
	    			delete y[k];
	    			setE({...y});
	    		}
	    	}else if (rule=="max") {
	    		if (v?.length>rules[rule]) {
	    			setE({...errors,[k]:`${k} must not be more than ${rules.max} characters.`});
	    		}else{
	    			let y={...errors};
	    			delete y[k];
	    			setE({...y});
	    		}
	    	}else if (rule=="validEmail") {
	    		var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  				if (v?.match(validRegex)) {
  					let y={...errors};
	    			delete y[k];
	    			setE({...y});
  				}else{
  					setE({...errors,[k]:"Invalid Email"});
  				}
	    	} 
 
    //})
    }

    const changeSomething=(k,v)=>{ 
    	setDa({...da,[k]:v});
 
    	if (k=="c_pass") {
    		//validateConditions(k,v,{min:5,max:10});
    		validateConditions(k,v,{min:8});
    		//validateConditions(k,v,{max:10});
    	}
        if (k=="n_pass") {
            validateConditions(k,v,{min:8});
        }  
    }
    //loading
    useEffect(()=>{
    	console.log(errors);
    	if (Object.keys(errors).length==0) {
    		setDisabled(false);
    	}else{
    		setDisabled(true);
    	}
    },[errors]); 

    const [done,setD]=useState(false);

    const [oldData,sOldData]=useState({});


    const updatepwd=()=>{
    	//let url="";
        const formData = new FormData();
        Object.keys(da).forEach((e)=>{
            formData.append(e, da[e]);
        });
        formData.append("token",localStorage.getItem("token",null));
        formData.append('updatepwd', 'updatepwd');
        

    	fetch(`${url}/api/user.php`, {
          method: "POST",
          body:formData
        }).then((res) => res.json()).then((c)=>{

            console.log(c);
            setNotice(c); 

        }).catch((e)=>{
            console.log(e);
        });
    } 

    useEffect(()=>{
        let y=localStorage.getItem("token");
        if (y==null) {
            window.location.href="";
        }else{
               setD(true);
            //getpro();
        }
    },[]);

    const confirmPass=(k,v)=>{ 
        //let k=
        if (da?.n_pass===v) {
            let y={...errors};
            delete y[k];
            setE({...y});
        }else{
            setE({...errors,[k]:"Password mismatch"});
        }
    }


    return (
        <ResetCtx.Provider
            value={{ 
                confirmPass,
                done,oldData,notice,
                disabled,loading,
                changeSomething,
                errors,updatepwd
            }}
        >
            {children}
        </ResetCtx.Provider>
    );
};

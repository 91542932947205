import styles from './loinput.module.scss';
import {useRef,useEffect} from 'react';
import i from '../icons/i.module.scss';

export default function LoInput({icon,label,onChange,placeholder,setValue='',type}){
	const r=useRef();
	useEffect(()=>{
		if (setValue) {
			r.current.value=setValue;
		}
	},[setValue]);
	return (<div className={styles.gi}>
			<label>{label}</label>
			<div className={styles.ig}>
				<div className={i?.[icon]}>
				</div>
				<input type={type} ref={r} onChange={onChange} placeholder={placeholder} />
			</div>
		</div>);
}

export  function LoInput2({label,style,eClassName=undefined,onChange,placeholder,setValue='',type}){
	const r=useRef();
	useEffect(()=>{
		if (setValue) {
			r.current.value=setValue;
		}
	},[setValue]);
	return (<div style={style} className={`${styles.iNo} ${(eClassName)?styles[eClassName]:null}`}>
			<label>{label}</label>
			<div className={styles.noI}> 
				<input type={type} ref={r} onChange={onChange} placeholder={placeholder} />
			</div>
		</div>);
}


export  function LoInput3({label,labelClass='',onChange,placeholder,setValue,type}){
	const r=useRef();
	useEffect(()=>{
		if (setValue) {
			r.current.value=setValue;
		}
	},[setValue]);
	return (<div className={styles.iNo2}>
			<label className={labelClass}>{label}</label>
			<div className={styles.noI2}> 
				<input type={type} ref={r} onChange={onChange} placeholder={placeholder} />
				<div className={styles.uis}>
					<div className={i.usd}></div> USD
				</div>
			</div>
		</div>);
}
import s from './das.module.scss';

import {url,FormatCurrency,GetFlag,userurl} from '../../common/shared';
import i from './i.module.scss';
import {DashboardProvider,useDashboardCtx} from './context';
import {useState} from 'react';
import { useLoaderData, useNavigation, Link } from "react-router-dom";
import SiteLoader from '../../common/SiteLoader';

import {LetterIcon,LetterIconSmall} from '../../common/LetterIcon';
import ShortNotification from '../../common/ShortNotification';

function slugger(input) {
    if (!input)
        return '';

    // make lower case and trim
    var slug = input.toLowerCase().trim();

    // remove accents from charaters
    slug = slug.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

    // replace invalid chars with spaces
    slug = slug.replace(/[^a-z0-9\s-]/g, ' ').trim();

    // replace multiple spaces or hyphens with a single hyphen
    slug = slug.replace(/[\s-]+/g, '-');

    return slug;
	//other_deliveries
}
function Progress({p=0}){
	return (<div className={s.prog}>{`${p}%`}
		<div className={s.pw}>
		<div className={s.ap} style={{'width':`${p}%`}}> </div>
		</div></div>);

}
export function HistoryItem({item}){
	return (<div className={s.orderItem}>
	<div>
		<div className={s.h}>Package From Delta </div>
		<div className={s.s}>Tracking ID #{item?.order_id}</div>
	</div>
	<div className={`${s.status} ${s?.[slugger(item?.delivery_status)]}`}>{item?.delivery_status}</div>
	</div>);
}
function Pair({title,body}){
	return (<div>
		<div className={s.odt}>{title}
		</div>
		<div className={s.odb}>{body}
		</div>
		</div>)
}

function Pair2({title,body}){
	return (<div>
		<div className={s.odt}>{title}
		</div>
		<div className={s.odb2}>{body}
		</div>
		</div>)
}
function Person({item}){
	return (<div className={s.pers}>
	<div className={s.photo}>
		<img src={`${url}/wp-user/uploads/${item?.da_photo}`} />
	</div>
	<div className={s.pdet}>
		<div >
			<div className={s.agentNa}>{item?.da_name}</div>
			<div className={s.role}>Delivery Agent </div>
		</div>
		<div className={s.duo}>
			{item?.da_email?<a className={s.mail} href={`mailto:${item?.da_email}`}> </a>:<div></div>}
			{item?.da_whatsapp?<a className={s.wa} href={`https://wa.me/${item?.da_whatsapp}`}> </a>:<div></div>}
		</div>
	</div>
	</div>);
}
function NoDeliveries(){
	return (<div className={s.noDeliveries}>

	<div className={s.chil}> 
		<div className={s.h}>No Shipments</div>
		<div className={s.b}>Your shipments should appear here contact support<br/> for any issues </div>
	</div>

	</div>);
}
export function InsideDashbaord(){

	const {details,delis} = useDashboardCtx();
	const [notice,setNotice]=useState(undefined);
    
	return (<div className={`${s.con} content`}>

	<ShortNotification newnotice={notice} cb={()=>{
			//alert("good");
			//window.location.reload();
		}} />

		<div className={s.top}>
			<div>
			<div className={s.pers}>
			<LetterIconSmall user={details?.user}  onClick={()=>{
					window.location.href=`${userurl}/account`;
				}}/>
				{/*<div className={s.photo} onClick={()=>{
					window.location.href=`${userurl}/account`;
				}}>
					<img src={details?.user?.picture?`${url}/wp-user/uploads/${details?.user?.picture}`:"photos/dp.svg"} />
				</div>*/}
				<div className={s.pdet}>
					<div >
						<div className={s.agentNa}>
						<div className={i.locato}></div>
						<div>{details?.user?.country}</div>
						<div><img src={`${url}/wp-user/flags/${GetFlag(details?.user?.country)}.svg`} /></div>
						</div>
						<div className={s.role}>{details?.user?.username}</div>
					</div>
					<div >
					</div>
				</div>
			</div>
			</div>
			<div className={`${i.logo} ${s.logo}`}>
			<img src="site/logo.png" />
			</div>
		</div>

		<div className={s.b1}>
		<div>
			<div className={s.wb}>Wallet Balance  </div>
			<div className={s.amt}>${FormatCurrency(details?.wb?.wallet_balance)}</div>
			<div><div className={s.cid}>
				<div className={s.w}>Customer ID : {details?.user?.customer_id} 
				</div>
				<div className={s.copyid} onClick={()=>{
					navigator.clipboard.writeText(details?.user?.customer_id).then(()=> {
						setNotice({type:'success',message:'Copied Succesfully'}); 
					}, function(err) {
						setNotice({type:'error',message:'Copy Failed'});  
					});
				}}>
					<div className={s.copyicon}></div>
					<div>Copy ID</div>
				</div> 
			</div></div>
		</div>
		<div style={{justifySelf: 'end'}}>
			<div className={s.db}><a href="deposit">Deposit</a></div>
		</div>
		</div>

		<div className={s.sh}>Shipments</div>
		{delis?.active_deliveries?.map((h,i)=>{
			return (<ShipmentItem item={h} key={i} />);
		})}
		<>
		{delis?.active_deliveries?.length==0?<NoDeliveries />:null}
		</>

		{delis?.other_deliveries?.map((h,i)=>{
			return (<HistoryItem item={h} key={i} />);
		})}

		
		

		{/*<HistoryItem />
		<HistoryItem />*/}


		</div>);
}

export function ShipmentItem({item}){
	return (<>
	<div className={s.shipment}>

		<div className={s.withbox}>
		<div className={s.withboxA}>
			<div className={s.otw}>{item?.delivery_status}</div>
			<div className={s.pf}>{item?.package_from}</div>
			<div className={s.oid}>Tracking ID #{item?.order_id}</div>
		</div>
		<div className={s.withboxB}> 
		</div>
		</div>

		<Progress p={item?.percent} />

		<div className={s.orderdetails}>
		<Pair title="Sender" body={item?.sender_name} />
		<Pair title="Weight " body={item?.package_weight}  />
		<Pair title="Price " body={`$${item?.package_price}`}  />
		<Pair title="Departure" body={`${new Date(item?.departure_date).toDateString()}`}  />
		</div>

		<hr className={s.odhr} />

		<div className={s.orderdetails}>
		<Pair2 title="Street" body={item?.street} />
		<Pair2 title="City " body={item?.city}  />
		<Pair2 title="Zip code  " body={item?.zip}  />
		<Pair2 title="Country" body={item?.country}  />
		</div>

		<hr className={s.odhr} />

		<Person item={item} />


		</div>
	</>);
}

export default function Dashboard(){
	const result = useLoaderData();
	const navigation = useNavigation(); 
	if (navigation.state === "loading") {
	    return (<h1>Loading!</h1>);
	}

	return (<DashboardProvider>
		<InsideDashbaord />
		</DashboardProvider>);
}